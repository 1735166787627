<template>
  <div class="container-page maintenanceService-page">
    <!-- 搜索区域 -->
    <div class="searchBox">
      <el-form inline ref="searchForm" :model="searchForm" :rules="rules">
        <el-form-item label="vin号">
          <el-input
            clearable
            size="small"
            placeholder="vin号"
            style="width: 9rem"
            v-model="searchForm.vin"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="userMobile">
          <el-input
            clearable
            size="small"
            placeholder="手机号"
            style="width: 9rem"
            v-model="searchForm.userMobile"
          ></el-input>
        </el-form-item>

        <el-form-item label="4S店">
          <el-input
            clearable
            size="small"
            placeholder="4S店"
            style="width: 9rem"
            v-model="searchForm.departmentName"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!--  表格列表区域 -->
    <el-table
      ref="tableData"
      class="tableBox"
      :data="tableData"
      v-loading="$store.state.requestLoading"
    >
      <el-table-column prop="vin" label="vin号"></el-table-column>
      <el-table-column prop="departmentName" label="4S店"></el-table-column>
      <el-table-column prop="departmentAddress" label="4S店地址"></el-table-column>
      <el-table-column prop="departmentMobile" label="4S店电话"></el-table-column>
      <el-table-column prop="userName" label="用户名"></el-table-column>
      <el-table-column prop="userMobile" label="手机号"></el-table-column>
      <el-table-column label="预约时间">
        <template slot-scope="scope">
          {{
            scope.row.createTime
              ? $util.dateFormat(scope.row.createTime)
              : $util.dateFormat(scope.row.createTime)
          }}
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pager">
      <el-pagination
        background
        :total="pager.total"
        :page-size="pager.pageSize"
        layout="total, prev, pager, next"
        @current-change="queryMaintenanceOrderRecord"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      // 搜索表单
      searchForm: {
        vin: '',
        userMobile: null,
        departmentName: null
      },

      // 表格数据
      tableData: [],

      // 分页相关
      pager: {
        total: 0,
        pageNo: 1,
        pageSize: 10
      },

      rules: {
        userMobile: [
          {
            validator: (rule, value, callback) => {
              const reg = /^[1][0-9]{10}$/ // 1开头的11位数字
              if (!value) {
                callback()
              } else if (!reg.test(value)) {
                callback(new Error('手机号格式不正确'))
              } else {
                callback()
              }
            },
            trigger: ['change', 'blur']
          }
        ]
      }
    }
  },
  mounted () {
    this.queryMaintenanceOrderRecord()
  },
  methods: {
    async queryMaintenanceOrderRecord (pageNo = 1) {
      this.pager.pageNo = pageNo

      this.searchForm.userMobile === '' && (this.searchForm.userMobile = null)
      this.searchForm.departmentName === '' && (this.searchForm.departmentName = null)

      const res = await this.$api.queryMaintenanceOrderRecord({
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        ...this.searchForm
      })
      this.tableData = res.data
      this.pager.total = res.total
    },
    // 查询
    search () {
      this.$refs.searchForm.validate((valid) => {
        valid && this.queryMaintenanceOrderRecord()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.maintenanceService-page {
  .searchBox {
    border: 0.5px solid #ebeef5;
    padding: 0.66rem 1.2rem 0.88rem;
    .el-form-item {
      margin-bottom: 0.66rem;
    }
  }
  .tableBox {
    border-left: 0.5px solid #ebeef5;
    border-right: 0.5px solid #ebeef5;
  }
  .pager {
    text-align: right;
    padding: 0.66rem 0 0;
  }
}
</style>
